




import { Component, Vue, Prop } from 'vue-property-decorator';
import { IImageBody } from './ContentImageMeta';
import { ILeafPageContent } from '../../store/types';

@Component<ContentImage>({})
export default class ContentImage extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IImageBody>;
}
