import { render, staticRenderFns } from "./PageSettings.vue?vue&type=template&id=2847bbd2&scoped=true&"
import script from "./PageSettings.ts?vue&type=script&lang=ts&"
export * from "./PageSettings.ts?vue&type=script&lang=ts&"
import style0 from "./PageSettings.vue?vue&type=style&index=0&id=2847bbd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2847bbd2",
  null
  
)

export default component.exports