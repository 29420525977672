import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { IPage } from '../store/types';

@Component
export default class Page extends Vue {
  @Getter
  protected page!: IPage;

  @Mutation
  protected selectComponent!: (componentId: string) => void;
}
