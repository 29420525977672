import { Component, Vue, Prop } from 'vue-property-decorator';
import * as componentRegistry from '../componentRegistry';
import { IPageContent } from '@/store/types';

@Component
export default class ComponentSettingsEdit extends Vue {
  @Prop({ type: Object })
  protected value!: IPageContent;

  // XXX copied code from `CompontentSettings`
  get selectedComponentMeta(): componentRegistry.IContentMeta<any> | null {
    if (this.value && 'type' in this.value) {
      return componentRegistry.getComponent(this.value.type);
    }
    // TODO probably not the nicest way
    return null;
  }
}
