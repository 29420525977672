import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPageContent } from '@/store/types';
import { getComponent, IContentMetaWithType, IStylingOption } from '@/componentRegistry';
import { startCase } from 'lodash';

@Component
export default class ComponentSettingsStyle extends Vue {
  @Prop({ type: Object })
  protected value!: IPageContent;

  get meta(): IContentMetaWithType<any> {
    return getComponent(this.value.type);
  }

  getTitle(option: IStylingOption): string {
    return startCase(option.type);
  }
}
