import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<App>({
  metaInfo(): MetaInfo {
    return {
      titleTemplate: this.titleTemplate,
    };
  },
})
export default class App extends Vue {
  titleTemplate(titleChunk: string): string {
    // If undefined or blank then we don't need the hyphen
    return titleChunk ? `${titleChunk} | Pagebase` : 'Pagebase';
  }
}
