import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { IPage, IPageContent } from '../store/types';

@Component<Viewer>({
  metaInfo(): MetaInfo {
    return {
      title: this.title,
    };
  },
})
export default class Viewer extends Vue {
  protected isLoading = false;

  @Prop({ type: String })
  protected pageIdentifier!: string;

  @Getter('page')
  protected page!: IPage;

  @Action('loadPage')
  protected loadPage!: (pageIdentifier?: string) => Promise<void>;

  /* returns the first headline on the page */
  get firstHeadline(): { index: number; element: IPageContent } {
    const content = this.page.body.children || [];
    const index = content.findIndex((c: any) => c.type === 'headline');
    return { index, element: content[index] };
  }

  /* returns the page's title based on the first headline */
  get title(): string {
    const headline = this.firstHeadline.element as any;
    return headline && headline.body && headline.body.text ? headline.body.text : undefined;
  }

  created(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.isLoading = true;
    if (this.pageIdentifier) {
      this.loadPage(this.pageIdentifier);
    }
    this.isLoading = false;
  }
}
