import { IContentMeta } from '@/componentRegistry';
import { Children } from '@/store/types';

export const meta: IContentMeta<Children> = {
  name: 'Section',
  description: 'Group many components as section.',
  icon: 'layer-group',
  body: {
    children: [],
  },
};
