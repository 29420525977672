






import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRichTextBody } from './ContentRichTextMeta';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentRichText extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IRichTextBody>;
}
