




import { Component, Vue, Prop } from 'vue-property-decorator';
import { PrismEditor } from 'vue-prism-editor';

import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs';
import 'prismjs/themes/prism.css';
import { ICodeBody } from './ContentCodeMeta';
import { ILeafPageContent } from '../../store/types';

// TODO: Select language
// TODO: Fix styling glitch when preview empty component

@Component<ContentCode>({
  components: {
    PrismEditor,
  },
})
export default class ContentCode extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ICodeBody>;
}
