import { upperFirst, camelCase, kebabCase } from 'lodash';

export interface IStylingOption {
  // references an existing styling component (see `./styling/*`)
  type: string;
  // TODO: maybe later allow overriding, customizing, etc.
}

export interface IContentMeta<B> {
  name: string;
  description: string;
  icon: string;
  body?: B;
  style?: string;
  stylingOptions?: IStylingOption[];
}

// XXX remove this later?
export interface IContentMetaWithType<B> extends IContentMeta<B> {
  type: string;
}

/* Returns all globally available components */
// TODO similar code in `/components/index.ts` -- move to once central place
// TODO consider caching?
export function getComponents(): IContentMetaWithType<any>[] {
  const requireComponent = require.context('@/components/content', true, /\.ts$/);
  return requireComponent
    .keys()
    .filter((fileName: string) => fileName.endsWith('Meta.ts')) // meta-descriptions for the components
    .map((fileName: string) => {
      const component: { meta: IContentMeta<any> } = requireComponent(fileName);
      // XXX no one understands this!!!
      const name = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));
      const trimmedName = name.replace(/^Content/, '').replace(/Meta$/, '');
      const type = kebabCase(trimmedName);
      return { ...component.meta, type };
    });
}

export function getComponent(type: string): IContentMetaWithType<any> {
  const meta = getComponents().find((component) => component.type === type);
  if (!meta) {
    throw new Error(`No component with type “${type}” found.`);
  }
  return meta;
}
