import { IContentMeta } from '@/componentRegistry';

export interface IRichTextBody {
  html: string;
}

export const meta: IContentMeta<IRichTextBody> = {
  name: 'Rich Text',
  description: 'Add rich text elements.',
  icon: 'align-left',
  stylingOptions: [
    { type: 'padding' },
    { type: 'color' },
    { type: 'background-color' },
    { type: 'font-size' },
    { type: 'text-align' },
  ],
};
