





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILinkBody } from './ContentLinkMeta';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentLink extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ILinkBody>;

  get link(): string {
    const url = this.value.body.href;
    return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  }

  copyRefToClipboard(ref: string): void {
    // @ts-ignore
    this.$refs[ref].select();
    document.execCommand('copy');
  }
}
