import { Component, Vue } from 'vue-property-decorator';
import { IPage } from '@/store/types';
import { Getter, Action, Mutation } from 'vuex-class';

@Component
export default class ToolBar extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected isDirty!: boolean;

  @Action
  protected loadPage!: (pageIdentifier?: string) => Promise<void>;

  @Action
  protected savePage!: () => Promise<{ new: boolean }>;

  @Action
  protected deletePage!: () => Promise<void>;

  @Mutation
  protected selectComponent!: (componentId: string) => void;

  async save(): Promise<void> {
    const result = await this.savePage();
    if (result.new) {
      await this.$router.push({
        name: 'editPage',
        // TODO better typing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        params: { pageIdentifier: this.page.identifier! },
      });
    }
  }

  newPage(): void {
    const route = this.$router.resolve('/');
    window.open(route.href, '_blank');
  }

  reset(): void {
    this.loadPage(this.page.identifier);
  }
}
