import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

// https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
const requireComponent = require.context('@/components', true, /\.vue$/);
requireComponent.keys().forEach((fileName: string) => {
  const componentConfig = requireComponent(fileName);
  const file = fileName.split('/').pop();
  if (!file) return;
  const componentName = upperFirst(camelCase(file.replace(/\.\w+$/, '')));
  Vue.component(componentName, componentConfig.default || componentConfig);
});
