import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class QrCodeModal extends Vue {
  @Prop({ type: String, default: window.location.href })
  protected url!: string;

  get qrCodeSrc(): string {
    return `https://api.qrcode.studio/qr/custom?data=${encodeURIComponent(this.url)}&size=512`;
  }
}
