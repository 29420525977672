import Vue from 'vue';
import Router from 'vue-router';
import Editor from '../views/Editor.vue';
import Viewer from '../views/Viewer.vue';
import Website from '../views/Website.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Confirm from '../views/Confirm.vue';
import RecoverPassword from '../views/RecoverPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import isLoggedInGuard from './guards/isLoggedIn.guard';

Vue.use(Router);

// TODO add 404 route

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Website,
      redirect: { name: 'homePage' },
      children: [
        {
          path: '/',
          name: 'homePage',
          component: Home,
        },
        {
          path: '/login',
          name: 'login',
          component: Login,
        },
        {
          path: '/register',
          name: 'register',
          component: Register,
        },
        {
          path: '/confirm/:userId/:code',
          name: 'confirm',
          component: Confirm,
        },
        {
          path: '/recover-password',
          name: 'recover-password',
          component: RecoverPassword,
        },
        {
          path: '/reset-password/:resetId/:token',
          name: 'reset-password',
          component: ResetPassword,
        },
      ],
    },
    {
      path: '/p/:pageIdentifier',
      name: 'viewPage',
      props: true,
      component: Viewer,
    },
    {
      path: '/e',
      name: 'newPage',
      props: true,
      component: Editor,
      beforeEnter: isLoggedInGuard,
      children: [
        {
          path: '/e/:pageIdentifier',
          name: 'editPage',
          props: true,
          component: Editor,
        },
      ],
    },
  ],
});
