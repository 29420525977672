import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TemplateCard extends Vue {
  /** The status type. */
  @Prop() readonly teaserHref: string | undefined;
  /** The status type. */
  @Prop() readonly title: string | undefined;
  /** The status type. */
  @Prop() readonly text: string | undefined;
}
