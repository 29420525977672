








import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import VueTrix from 'vue-trix';
import { Action } from 'vuex-class';
import { IRichTextBody } from './ContentRichTextMeta';
import { ILeafPageContent } from '../../store/types';

@Component<ContentRichTextEdit>({
  components: { VueTrix },
})
export default class ContentRichTextEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IRichTextBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<IRichTextBody>) => void;

  updateHtml(html: string): void {
    this.updateComponent({ ...this.value, body: { html } });
  }
}
