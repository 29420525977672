













import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITextBody } from './ContentTextMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentTextEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ITextBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<ITextBody>) => void;

  updateText(text: string): void {
    this.updateComponent({ ...this.value, body: { text } });
  }
}
