import { IContentMeta } from '@/componentRegistry';

export interface ICountdownBody {
  date: string;
  time: string;
}

export const meta: IContentMeta<ICountdownBody> = {
  name: 'Countdown',
  description: 'Show a running timer.',
  icon: 'hourglass-half',
  body: {
    date: '2021-01-01',
    time: '00:00',
  },
};
