import Vue from 'vue';
import { ActionContext, MutationTree, ActionTree, Module, GetterTree } from 'vuex';
import router from '../../router';
import { IPage, IPageContent, IPageState, IRootState, IPageTemplate } from '../types';
import * as utils from '../../utils';
import { cloneDeep } from 'lodash';
import ObjectID from 'bson-objectid';
import { PageModel, IContainer } from './utils/pageModel';

export interface IAddArgs {
  parentId: string;
  component: IPageContent;
  index?: number;
}

export interface IMoveArgs {
  parentId: string;
  from: number;
  to: number;
}

export interface IDeleteArgs {
  parentId?: string;
  componentId: string;
}

export type CurrentSelection = 'nothing' | 'page' | 'component';

const getDefaultState = (): IPageState => {
  return {
    isDirty: true,
    page: new PageModel({
      _id: new ObjectID().toHexString(), // TODO is this clever?
      body: {
        children: [
          {
            _id: new ObjectID().toHexString(),
            type: 'headline',
            body: {
              text: '',
            },
          },
        ] as IPageContent[],
      },
      style: {},
      owner: null,
    }),
    selectedComponentId: null, // nb: explicitly must initialize this
  } as IPageState;
};

const state: IPageState = getDefaultState();

const getters: GetterTree<IPageState, IRootState> = {
  isDirty: (state: IPageState) => state.isDirty,
  page: (state: IPageState) => state.page,
  selectedComponent: (state: IPageState) =>
    state.selectedComponentId ? state.page.getContent(state.selectedComponentId) : null,
  currentSelection: (state: IPageState): CurrentSelection => {
    if (!state.selectedComponentId) {
      return 'nothing';
    }
    if (state.selectedComponentId === state.page._id) {
      return 'page';
    }
    return 'component';
  },
  isSelected: (state: IPageState) => (componentId: string) => state.selectedComponentId === componentId,
};

// XXX confusion between `const state` and local states in mutations?!

const mutations: MutationTree<IPageState> = {
  resetState: (state: IPageState) => {
    Object.assign(state, getDefaultState());
  },
  setDirty: (state: IPageState, isDirty: boolean) => {
    state.isDirty = isDirty;
  },
  setPage: (state: IPageState, page: IPage) => {
    state.page = new PageModel(page);
  },
  addComponent: (state: IPageState, addArgs: IAddArgs) => {
    const destination = state.page.getContent(addArgs.parentId);
    if (!destination) {
      return;
    }
    if (!addArgs.component._id) {
      addArgs.component._id = new ObjectID().toHexString();
    }
    const index = typeof addArgs.index === 'number' ? addArgs.index : destination.body.children.length - 1;
    destination.body.children.splice(index, 0, addArgs.component);
    state.selectedComponentId = addArgs.component._id;
  },
  selectComponent: (state: IPageState, componentId: string) => {
    state.selectedComponentId = componentId;
  },
  updateComponent: (state: IPageState, content: IPageContent) => {
    const storedComponent = state.page.getContent(content._id);
    if (!storedComponent) {
      return;
    }
    storedComponent.body = content.body;
    storedComponent.style = content.style;
  },
  moveComponent: (state: IPageState, moveArgs: IMoveArgs) => {
    const destination = state.page.getContent(moveArgs.parentId);
    if (!destination) {
      return;
    }
    const item = destination.body.children.splice(moveArgs.from, 1);
    destination.body.children.splice(moveArgs.to, 0, item[0]);
  },
  duplicateSelectedComponent: (state: IPageState) => {
    if (!state.selectedComponentId) return;
    const selectedComponent = state.page.getContent(state.selectedComponentId);
    if (!selectedComponent) {
      return;
    }
    const clonedComponent = cloneDeep(selectedComponent);
    delete clonedComponent._id;
    // @ts-ignore FIXME
    state.page.body.children.push(clonedComponent);
  },
  deleteComponent: (state: IPageState, deleteArgs: IDeleteArgs) => {
    const parent = deleteArgs.parentId
      ? (state.page.getContent(deleteArgs.parentId) as IContainer)
      : state.page.getParent(deleteArgs.componentId);
    if (parent) {
      const idx = parent.body.children.findIndex((c) => c._id === deleteArgs.componentId);
      parent.body.children.splice(idx, 1);
    }
  },
};

const actions: ActionTree<IPageState, IRootState> = {
  addComponent: (context: ActionContext<IPageState, IRootState>, component: IPageContent) => {
    context.commit('addComponent', component);
    context.commit('setDirty', true);
    // XXX is this nice?
    // context.commit('selectComponent', context.state.page.body.children.length - 1);
  },
  updatePage: (context: ActionContext<IPageState, IRootState>, page: IPage) => {
    context.commit('setPage', page);
    context.commit('setDirty', true);
  },
  loadPage: async (context: ActionContext<IPageState, IRootState>, pageIdentifier?: string) => {
    // const prevSelectedComponentIdx = context.rootState.pages.selectedComponentIdx;
    // const newSelectedComponentIdx = (prevSelectedComponentIdx < 0) ? prevSelectedComponentIdx : -1;

    // Set default page if pageIdentifier is not provided
    if (!pageIdentifier) {
      const template = await Vue.axios.get<IPageTemplate>('/template/default');
      context.commit('setPage', template.data.page);
      return;
    }

    // Load page
    try {
      const response = await Vue.axios.get<IPage>(`/page/${pageIdentifier}`);
      context.commit('setPage', response.data);
      // context.commit('selectComponent', newSelectedComponentIdx);
      context.commit('setDirty', false);
    } catch (err) {
      if (utils.isAxiosError(err) && err.response && err.response.status === 401) {
        await context.dispatch('loadPage', pageIdentifier);
        context.commit('raiseNotification', {
          type: 'danger',
          title: "We don't know you!",
          text: `
            The edit token you provided does not match the page's edit token.
            Unfortunately, we can not let you edit this page. Please provide
            the proper token or start with a new page.
          `,
        });
      }
    }
  },
  savePage: async (context: ActionContext<IPageState, IRootState>) => {
    if (context.state.page.created) {
      // Update existing page
      const response = await Vue.axios.put<IPage>(`/page/${context.state.page.identifier}`, context.state.page);
      context.commit('setPage', response.data);
      context.commit('setDirty', false);
      return { new: false };
    } else {
      // Create new page
      const response = await Vue.axios.post<IPage>('/page', context.state.page);
      context.commit('setPage', response.data);
      context.commit('setDirty', false);
      return { new: true };
    }
  },
  deletePage: async (context: ActionContext<IPageState, IRootState>) => {
    if (!context.state.page.created) {
      // Set default page if page has no identifier, i.e.
      // it was not saved and post'ed yet
      context.commit('resetState');
    } else {
      await Vue.axios.delete<IPage>(`/page/${context.state.page.identifier}`);
      context.commit('resetState');
      await router.push({ path: '/' });
    }
  },
  updateComponent: (context: ActionContext<IPageState, IRootState>, body: any) => {
    context.commit('updateComponent', body);
    context.commit('setDirty', true);
  },
  moveComponent: (context: ActionContext<IPageState, IRootState>, moveArgs: IMoveArgs) => {
    context.commit('moveComponent', moveArgs);
    context.commit('setDirty', true);
    // context.commit('selectComponent', moveArgs.to);
  },
  copySelectedComponent: (context: ActionContext<IPageState, IRootState>) => {
    context.commit('duplicateSelectedComponent');
    // context.commit('selectComponent', context.rootState.pages.page.body.children.length - 1);
    context.commit('setDirty', true);
  },
  deleteComponent: (context: ActionContext<IPageState, IRootState>, deleteArgs: IDeleteArgs) => {
    context.commit('deleteComponent', deleteArgs);
    // TODO selectComponent
    context.commit('setDirty', true);
  },
};

const namespaced = false;

const module: Module<IPageState, IRootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

export default module;
