import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import VueScrollClass from 'vue-scroll-class';

@Component({
  directives: { 'scroll-class': VueScrollClass },
})
export default class WebsiteNavigationBar extends Vue {
  protected user: { email: string } | null = null;

  async created(): Promise<void> {
    await this.reloadUser();
  }

  async logout(): Promise<void> {
    window.localStorage.removeItem('token');
    await this.reloadUser();
  }

  private async reloadUser() {
    try {
      const response = await Vue.axios.get<{ email: string }>('/user/me');
      this.user = response.data;
    } catch (e) {
      // not logged in
      this.user = null;
    }
  }
}
