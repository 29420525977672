import { IPage, Style, Children, IPageContent, ISectionPageContent } from '@/store/types';

export type IContent = IPage | IPageContent;
export type IContainer = IPage | ISectionPageContent;

export class PageModel implements IPage {
  _id: any;
  identifier?: string;
  body: Children;
  style: Style;
  created?: Date;
  updated?: Date;
  owner: any;

  constructor(data: IPage) {
    this._id = data._id;
    this.identifier = data.identifier;
    this.body = data.body;
    this.style = data.style;
    this.created = data.created;
    this.updated = data.updated;
  }

  getContent(id: string): IContent | undefined {
    const result = PageModel._getContent(id, this);
    return result[result.length - 1];
  }

  getParent(id: string): IContainer | undefined {
    const result = PageModel._getContent(id, this);
    const parent = result[result.length - 2];
    if (parent && !PageModel.isContainer(parent)) {
      throw new Error('Expected parent to be a container'); // should never happen
    }
    return parent;
  }

  private static _getContent(id: string, content: IContent, ancestors: IContent[] = []): IContent[] {
    if (content._id === id) {
      return [...ancestors, content];
    }
    if (this.isContainer(content)) {
      for (const child of content.body.children) {
        const result = this._getContent(id, child, [...ancestors, content]);
        if (result.length) {
          return result;
        }
      }
    }
    return [];
  }

  private static isContainer(content: IContent): content is IContainer {
    return 'children' in content.body;
  }
}
