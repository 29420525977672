






import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITextBody } from './ContentTextMeta';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentText extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ITextBody>;
}
