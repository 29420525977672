import { Component, Vue } from 'vue-property-decorator';
import { IPage } from '@/store/types';
import { Getter } from 'vuex-class';
import * as utils from '../utils';

@Component
export default class PageSettingsShare extends Vue {
  protected email: string | null = null;
  protected emailDisabled = false;

  @Getter
  protected page!: IPage;

  get isSharable(): boolean {
    return !!this.page.identifier;
  }

  get shareLink(): string {
    return `${window.location.origin}/p/${this.page.identifier}`;
  }

  copyRefToClipboard(ref: string): void {
    // @ts-ignore
    this.$refs[ref].select();
    document.execCommand('copy');
  }

  async sendEmail(): Promise<void> {
    this.emailDisabled = true;
    const data = { email: this.email };
    try {
      await Vue.axios.post(`/page/${this.page.identifier}/send-links-by-email`, data);
      this.email = 'Email sent!';
    } catch (err) {
      this.email = utils.isAxiosError(err) && err.response ? err.response.data.message : err.message;
      this.emailDisabled = false;
    }
  }
}
