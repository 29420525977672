

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPageContent } from '../../store/types';
import { Action } from 'vuex-class';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAlignLeft, faAlignRight, faAlignCenter, faAlignJustify } from '@fortawesome/free-solid-svg-icons';

library.add(faAlignLeft, faAlignRight, faAlignCenter, faAlignJustify);

@Component
export default class StylingTextAlign extends Vue {
  @Prop({ type: Object })
  protected value!: IPageContent;

  @Action
  protected updateComponent!: (value: IPageContent) => void;

  updateProperty(value: string): void {
    this.updateComponent({ ...this.value, style: { ...this.value.style, 'text-align': value } });
  }

  selected(value: string): boolean {
    return this.value.style['text-align'] === value;
  }
}
