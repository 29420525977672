import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { API_URL } from '../constants';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = API_URL;

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
