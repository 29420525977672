





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILinkBody } from './ContentLinkMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentLinkEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ILinkBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<ILinkBody>) => void;

  // TODO -- we had a `v-model.trim` before?

  updateHref(href: string): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, href } });
  }

  updateTitle(title: string): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, title } });
  }
}
