import { IContentMeta } from '@/componentRegistry';

export interface ITextBody {
  text: string;
}

export const meta: IContentMeta<null> = {
  name: 'Text',
  description: 'Add unstyled text elements.',
  icon: 'align-left',
};
