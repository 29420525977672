import { MutationTree, Module, GetterTree } from 'vuex';
import { INotificationState, IRootState, INotification } from '../types';

const state: INotificationState = {
  notification: null,
};

const getters: GetterTree<INotificationState, IRootState> = {
  notification: (state: INotificationState) => state.notification,
};

const mutations: MutationTree<INotificationState> = {
  raiseNotification(state: INotificationState, notification: INotification) {
    state.notification = notification;
  },
  dismissNotification(state: INotificationState) {
    state.notification = null;
  },
};

const namespaced = false;

const module: Module<INotificationState, IRootState> = {
  namespaced,
  state,
  getters,
  mutations,
};

export default module;
