import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faAlignLeft,
  faArrowLeft,
  faBan,
  faBars,
  faBell,
  faBrain,
  faCalendar,
  faCalendarDay,
  faChartBar,
  faChevronDown,
  faClipboard,
  faClock,
  faCode,
  faCog,
  faCopy,
  faDesktop,
  faEnvelope,
  faEnvelopeOpen,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFont,
  faGlobeAmericas,
  faHeading,
  faHourglassHalf,
  faImage,
  faImages,
  faLayerGroup,
  faLink,
  faMap,
  faMapMarkerAlt,
  faMobileAlt,
  faPalette,
  faPen,
  faPencilAlt,
  faPhone,
  faPlus,
  faQrcode,
  faRedo,
  faSave,
  faSearch,
  faShare,
  faShareAlt,
  faSignOutAlt,
  faSmile,
  faSquare,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTree,
  faUndo,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
// need to use prefix 'fab' -- see:
// https://github.com/FortAwesome/vue-fontawesome#explicit-prefix-note-the-vue-bind-shorthand-because-this-uses-an-array
// https://github.com/FortAwesome/vue-fontawesome#why-so-explicit-the-iconfar-coffee-syntax
import {
  faBehance,
  faFacebook,
  faInstagram,
  faLinkedin,
  faSpotify,
  faTwitter,
  faXing,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faAlignLeft,
  faArrowLeft,
  faBan,
  faBars,
  faBehance,
  faBell,
  faBrain,
  faCalendar,
  faCalendarDay,
  faChartBar,
  faChevronDown,
  faClipboard,
  faClock,
  faCode,
  faCog,
  faCopy,
  faDesktop,
  faEnvelope,
  faEnvelopeOpen,
  faExternalLinkAlt,
  faEye,
  faFacebook,
  faFile,
  faFont,
  faGlobeAmericas,
  faHeading,
  faHourglassHalf,
  faImage,
  faImages,
  faInstagram,
  faLayerGroup,
  faLink,
  faLinkedin,
  faMap,
  faMapMarkerAlt,
  faMobileAlt,
  faPalette,
  faPen,
  faPencilAlt,
  faPhone,
  faPlus,
  faQrcode,
  faRedo,
  faSave,
  faSearch,
  faShare,
  faShareAlt,
  faSignOutAlt,
  faSmile,
  faSpotify,
  faSquare,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTree,
  faTwitter,
  faUndo,
  faUser,
  faXing
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
