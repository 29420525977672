import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { IPage, ILeafPageContent } from '../store/types';
import { IHeadlineBody } from '@/components/content/ContentHeadlineMeta';

@Component<Editor>({
  metaInfo(): MetaInfo {
    return {
      title: this.title,
    };
  },
})
export default class Editor extends Vue {
  protected isLoading = false;

  @Prop({ type: String })
  protected pageIdentifier: string | undefined;

  @Getter('page')
  protected page!: IPage;

  @Action('loadPage')
  protected loadPage!: (pageIdentifier?: string) => Promise<void>;

  /* returns the first headline on the page */
  get firstHeadline(): ILeafPageContent<IHeadlineBody> | null {
    const content = this.page.body.children || [];
    const headlines = content.filter(
      (content): content is ILeafPageContent<IHeadlineBody> => content.type === 'headline'
    );
    return headlines[0];
  }

  /* returns the page's title based on the first headline */
  get title(): string | undefined {
    const headline = this.firstHeadline;
    return headline && headline.body && headline.body.text ? headline.body.text : undefined;
  }

  created(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.isLoading = true;
    this.loadPage(this.pageIdentifier);
    this.isLoading = false;
  }
}
