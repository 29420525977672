import { Component, Vue } from 'vue-property-decorator';
import { IPage } from '@/store/types';
import { Getter, Action } from 'vuex-class';
import * as componentRegistry from '../componentRegistry';
import { IContainer } from '@/store/modules/utils/pageModel';
import { IDeleteArgs } from '@/store/modules/pages';

@Component
export default class ComponentSettings extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected selectedComponent!: IContainer | undefined;

  @Action
  protected copySelectedComponent!: () => void;

  @Action
  protected deleteComponent!: (deleteArgs: IDeleteArgs) => void;

  get selectedComponentMeta(): componentRegistry.IContentMeta<any> | null {
    if (this.selectedComponent && 'type' in this.selectedComponent) {
      return componentRegistry.getComponent(this.selectedComponent.type);
    }
    // TODO probably not the nicest way
    return null;
  }

  copyComponent(): void {
    // TODO new component should appear under the currently selected one
    this.copySelectedComponent();
  }
}
