import { IContentMeta } from '@/componentRegistry';
import * as L from 'leaflet';

export interface IMapBody {
  zoom: number;
  center: Partial<L.LatLng>;
  bounds?: Partial<L.LatLngBounds>;
  marker: Partial<L.LatLng>;
}

export const meta: IContentMeta<IMapBody> = {
  name: 'Map',
  description: 'Show a route or location on your page.',
  icon: 'map',
  body: {
    zoom: 14,
    center: {
      lat: 51.0580992089485,
      lng: 13.7413106043823,
    },
    marker: {
      lat: 51.0580992089485,
      lng: 13.7413106043823,
    },
  },
};
