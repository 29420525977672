












import { Component, Vue, Prop } from 'vue-property-decorator';
import { PrismEditor } from 'vue-prism-editor';

import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs';
import 'prismjs/themes/prism.css';
import { ICodeBody } from './ContentCodeMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

// TODO: Select language
// TODO: Fix styling glitch when preview empty component
// TODO: does not properly update when deleting code content

@Component<ContentCodeEdit>({
  components: {
    PrismEditor,
  },
})
export default class ContentCodeEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ICodeBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<ICodeBody>) => void;

  updateCode(code: string): void {
    this.updateComponent({ ...this.value, body: { code } });
  }
}
