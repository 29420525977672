import { Component, Vue } from 'vue-property-decorator';
import * as utils from '../utils';

@Component
export default class Login extends Vue {
  protected data: { email?: string; password?: string } = {};
  protected submitting = false;
  protected errorMessage: string | null = null;
  /** Prevent re-submitting same values when invalid credentials were entered. */
  protected preventResubmission = false;

  async login(): Promise<void> {
    try {
      this.submitting = true;
      this.errorMessage = null;
      const response = await Vue.axios.post<{ token: string }>('/user/login', this.data);
      window.localStorage.setItem('token', response.data.token);
      await this.$router.push({ name: 'newPage' });
    } catch (e) {
      if (utils.isAxiosErrorWithResponse(e)) {
        switch (e.response.status) {
          case 401:
            this.errorMessage =
              'Sorry. Either the email address or password is invalid. You can use the link below to recover your password.';
            this.preventResubmission = true;
            break;
        }
      } else {
        this.errorMessage =
          'Oups. Something went wrong here. Either your internet connection is not working or our server failed to respond. We suggest to simply try it again and hope for the best.';
      }
    } finally {
      this.submitting = false;
    }
  }

  clearError(): void {
    this.errorMessage = null;
    this.preventResubmission = false;
  }
}
