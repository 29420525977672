











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { IHeadlineBody } from './ContentHeadlineMeta';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentHeadlineEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IHeadlineBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<IHeadlineBody>) => void;

  updateText(text: string): void {
    this.updateComponent({ ...this.value, body: { text } });
  }
}
