









import { Component, Vue, Prop } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { IMapBody } from './ContentMapMeta';
import { ILeafPageContent } from '../../store/types';

@Component<ContentMap>({
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
})
export default class ContentMap extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IMapBody>;

  protected url = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
  protected attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
}
