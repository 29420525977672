






import { Component, Vue, Prop } from 'vue-property-decorator';
import { IHeadlineBody } from './ContentHeadlineMeta';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentHeadline extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<IHeadlineBody>;
}
