import { Component, Vue } from 'vue-property-decorator';
import { IPage } from '@/store/types';
import { Getter, Action } from 'vuex-class';
import * as componentRegistry from '../componentRegistry';
import { IAddArgs } from '@/store/modules/pages';

@Component
export default class ComponentList extends Vue {
  protected filterComponents = '';

  @Getter('page')
  protected page!: IPage;

  @Action('addComponent')
  protected addComponent!: (addArgs: IAddArgs) => void;

  get components(): componentRegistry.IContentMetaWithType<any>[] {
    return componentRegistry.getComponents().filter((component) => {
      const matcher = new RegExp(this.filterComponents, 'i');
      return component.name.match(matcher) || component.description.match(matcher);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  newContent(type: any): void {
    const component = this.components.find((c) => c.type === type);
    const body = component && component.body ? component.body : {};
    const style = component && component.style ? component.style : {};
    this.addComponent({ parentId: this.page._id, component: { type, body, style } });
  }
}
