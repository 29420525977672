import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import { IRootState } from './types';

Vue.use(Vuex);

export default new Vuex.Store<IRootState>({
  strict: true,
  modules,
});
