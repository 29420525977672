


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Mutation, Action } from 'vuex-class';
import { IPageContent } from '../../store/types';
// @ts-ignore
import draggable from 'vuedraggable';
import * as componentRegistry from '../../componentRegistry';
import { IMoveArgs, IAddArgs, IDeleteArgs } from '../../store/modules/pages';

@Component({
  components: {
    draggable,
  },
})
export default class ContentSection extends Vue {
  @Prop({ type: Object })
  protected value!: IPageContent;

  @Getter
  protected isSelected!: (componentId: string) => boolean;

  @Mutation
  protected selectComponent!: (componentId: string) => void;

  @Action
  protected addComponent!: (addArgs: IAddArgs) => void;

  @Action
  protected deleteComponent!: (deleteArgs: IDeleteArgs) => void;

  @Action
  protected moveComponent!: (moveArgs: IMoveArgs) => void;

  // XXX make this a filter?
  getComponent(content: IPageContent): componentRegistry.IContentMetaWithType<any> {
    return componentRegistry.getComponent(content.type);
  }

  change(e: DraggableChangeEvent<IPageContent>): void {
    console.log(`event: ${JSON.stringify(e)}, parentId: ${this.value._id}`);
    if (isAdded(e)) {
      // moving a component from *outside* to this draggable
      this.addComponent({ component: e.added.element, parentId: this.value._id, index: e.added.newIndex });
    } else if (isRemoved(e)) {
      // dragging component form this to other draggable
      this.deleteComponent({ componentId: e.removed.element._id, parentId: this.value._id });
    } else if (isMoved(e)) {
      // moving a component *within* the same draggable
      this.moveComponent({ from: e.moved.oldIndex, to: e.moved.newIndex, parentId: this.value._id });
    } else {
      console.error('Unexpected event', e);
    }
  }

  createStyle(style: { [key: string]: string }): string {
    return Object.entries(style || {})
      .map(([key, value]) => `${key}: ${value};`)
      .join('');
  }
}

// TODO move to global d.ts
// https://github.com/SortableJS/Vue.Draggable#events
type DraggableChangeEvent<E> = IDraggableAddedEvent<E> | IDraggableRemovedEvent<E> | IDraggableMovedEvent<E>;

interface IDraggableAddedEvent<E> {
  added: { newIndex: number; element: E };
}

interface IDraggableRemovedEvent<E> {
  removed: { oldIndex: number; element: E };
}

interface IDraggableMovedEvent<E> {
  moved: { newIndex: number; oldIndex: number; element: E };
}

function isAdded<E>(e: DraggableChangeEvent<E>): e is IDraggableAddedEvent<E> {
  return 'added' in e;
}

function isRemoved<E>(e: DraggableChangeEvent<E>): e is IDraggableRemovedEvent<E> {
  return 'removed' in e;
}

function isMoved<E>(e: DraggableChangeEvent<E>): e is IDraggableMovedEvent<E> {
  return 'moved' in e;
}
