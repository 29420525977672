import { render, staticRenderFns } from "./ComponentSettingsStyle.vue?vue&type=template&id=75305520&scoped=true&"
import script from "./ComponentSettingsStyle.ts?vue&type=script&lang=ts&"
export * from "./ComponentSettingsStyle.ts?vue&type=script&lang=ts&"
import style0 from "./ComponentSettingsStyle.vue?vue&type=style&index=0&id=75305520&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75305520",
  null
  
)

export default component.exports