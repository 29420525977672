




import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPageContent } from '../../store/types';
import { Action } from 'vuex-class';

@Component
export default class StylingColor extends Vue {
  @Prop({ type: Object })
  protected value!: IPageContent;

  @Action
  protected updateComponent!: (value: IPageContent) => void;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateProperty(value: any): void {
    this.updateComponent({ ...this.value, style: { ...this.value.style, color: value } });
  }
}
