import { Component, Vue } from 'vue-property-decorator';
import * as utils from '../utils';

@Component
export default class ResetPassword extends Vue {
  protected validRecoveryLink = false;
  protected data: { resetId?: string; token?: string; password?: string } = {};
  protected submitting = false;
  protected errorMessage: string | null = null;

  async created(): Promise<void> {
    this.data.resetId = this.$route.params.resetId;
    this.data.token = this.$route.params.token;
    try {
      await Vue.axios.post('/user/password/reset/check', this.data);
      this.validRecoveryLink = true;
    } catch (e) {
      this.errorMessage = 'Meeeh. This does not look like a valid password recovery link, sorry.';
    }
  }

  async reset(): Promise<void> {
    try {
      this.submitting = true;
      this.errorMessage = null;
      const response = await Vue.axios.post<{ token: string }>('/user/password/reset', this.data);
      window.localStorage.setItem('token', response.data.token);
      await this.$router.push({ name: 'newPage' });
    } catch (e) {
      if (utils.isAxiosErrorWithResponse(e)) {
        switch (e.response.status) {
          case 400:
            this.errorMessage = 'D’oh. Something with the entered data seems wrong.';
            return;
        }
      } else {
        this.errorMessage =
          'Oups. Something went wrong here. Either your internet connection is not working or our server failed to respond. We suggest to simply try it again and hope for the best.';
      }
    } finally {
      this.submitting = false;
    }
  }
}
