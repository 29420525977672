import { Component, Vue } from 'vue-property-decorator';
import { IPage } from '@/store/types';
import { Getter, Action } from 'vuex-class';

@Component
export default class PageSettings extends Vue {
  @Getter
  protected page!: IPage;

  @Action
  protected deletePage!: () => Promise<void>;
}
