import { IContentMeta } from '@/componentRegistry';

export interface IHeadlineBody {
  text: string;
}

export const meta: IContentMeta<IHeadlineBody> = {
  name: 'Headline',
  description: 'Structure your page with headlines.',
  icon: 'heading',
};
