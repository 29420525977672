







































import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUploadComponent from 'vue-upload-component';
import { IMGUR_CLIENT_ID } from '../../constants';
import { IImageBody } from './ContentImageMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

@Component<ContentImageEdit>({
  components: {
    VueUploadComponent,
  },
})
export default class ContentImageEdit extends Vue {
  protected IMGUR_CLIENT_ID = IMGUR_CLIENT_ID;

  @Prop({ type: Object })
  protected value!: ILeafPageContent<IImageBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<IImageBody>) => void;

  inputFile(newFile: VUFile, oldFile: VUFile): void {
    if (newFile && newFile.success) {
      const response: any = newFile.response;
      if (response.success) {
        this.updateImageSrc(response.value.link);
      }
    }

    // Automatic upload
    const upload = this.$refs.upload as VueUploadComponent;
    if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
      if (!upload.active) {
        upload.active = true;
      }
    }
  }

  updateImageSrc(src: string): void {
    this.updateComponent({ ...this.value, body: { src } });
  }
}
