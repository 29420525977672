

























import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import VueCountdown from '@chenfengyuan/vue-countdown';
import { ICountdownBody } from './ContentCountdownMeta';
import { ILeafPageContent } from '../../store/types';

@Component<ContentCountdown>({
  components: {
    VueCountdown,
  },
})
export default class ContentCountdown extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ICountdownBody>;

  get timeMs(): number {
    return Date.parse(`${this.value.body.date}T${this.value.body.time}`) - Date.now();
  }
}
