import { Component, Vue } from 'vue-property-decorator';
import * as utils from '../utils';

@Component
export default class RecoverPassword extends Vue {
  protected data: { email?: string } = {};
  protected recoveryRequestSent = false;
  protected submitting = false;
  protected errorMessage: string | null = null;

  async recover(): Promise<void> {
    try {
      this.submitting = true;
      this.errorMessage = null;
      await Vue.axios.post('/user/password/recover', this.data);
      this.recoveryRequestSent = true;
    } catch (e) {
      if (utils.isAxiosErrorWithResponse(e)) {
        switch (e.response.status) {
          case 400:
            this.errorMessage = 'D’oh. Something with the entered data seems wrong.';
            return;
        }
      } else {
        this.errorMessage =
          'Oups. Something went wrong here. Either your internet connection is not working or our server failed to respond. We suggest to simply try it again and hope for the best.';
      }
    } finally {
      this.submitting = false;
    }
  }
}
