

















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { IMapBody } from './ContentMapMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

@Component<ContentMapEdit>({
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
})
export default class ContentMapEdit extends Vue {
  protected readonly url = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';

  @Prop({ type: Object })
  protected value!: ILeafPageContent<IMapBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<IMapBody>) => void;

  zoomUpdated(zoom: number): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, zoom } });
  }

  centerUpdated(center: L.LatLng): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, center } });
  }

  boundsUpdated(bounds: L.LatLngBounds): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, bounds } });
  }

  markerUpdated(marker: L.LatLng): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, marker } });
  }
}
