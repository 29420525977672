import { Vue } from 'vue-property-decorator';
import { NavigationGuard } from 'vue-router';

const isLoggedInGuard: NavigationGuard = async (to, from, next) => {
  const token = window.localStorage.getItem('token');
  if (!token) {
    next('/login');
  } else {
    try {
      await Vue.axios.get<{ email: string }>('/user/me');
      next();
    } catch (e) {
      next('/login');
    }
  }
};

export default isLoggedInGuard;
