import { AxiosError, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isAxiosError(err: any): err is AxiosError {
  return err.isAxiosError;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isAxiosErrorWithResponse(err: any): err is AxiosError & { response: AxiosResponse<any> } {
  return isAxiosError(err) && !!err.response;
}
