import { IContentMeta } from '@/componentRegistry';

export interface ILinkBody {
  title: string;
  href: string;
}

export const meta: IContentMeta<ILinkBody> = {
  name: 'Links',
  description: 'Add custom link with icon.',
  icon: 'link',
};
