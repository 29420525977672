

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICountdownBody } from './ContentCountdownMeta';
import { Action } from 'vuex-class';
import { ILeafPageContent } from '../../store/types';

@Component
export default class ContentCountdownEdit extends Vue {
  @Prop({ type: Object })
  protected value!: ILeafPageContent<ICountdownBody>;

  @Action
  protected updateComponent!: (value: ILeafPageContent<ICountdownBody>) => void;

  // TODO -- we had a `v-model.trim` before?

  updateDate(date: string): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, date } });
  }

  updateTime(time: string): void {
    this.updateComponent({ ...this.value, body: { ...this.value.body, time } });
  }
}
