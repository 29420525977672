import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import isURL from 'validator/lib/isURL';

extend('required', {
  ...required,
});

extend('url', {
  validate: (value) => isURL(value),
});

extend('delete', {
  validate: (value) => {
    return (value as string).trim().toLowerCase() === 'delete';
  },
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
