import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Confirm extends Vue {
  protected message: string | null = null;

  async created(): Promise<void> {
    const { userId, code } = this.$route.params;
    try {
      await Vue.axios.post('/user/confirm', { userId, code });
      this.message =
        'Thank you and welcome! Your account is now officially confirmed. We will redirect you in 3, 2, 1 seconds.';
      await this.timeout(5 * 1000);
      await this.$router.push({ name: 'newPage' });
    } catch (e) {
      this.message =
        'Oupsies. Either the confirmation link was invalid, or the corresponding account was already confirmed. If you already clicked the confirmation link, you can proceed to the “Login” now.';
    }
  }

  private timeout(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
  }
}
