import { IContentMeta } from '@/componentRegistry';

export interface IImageBody {
  src: string;
}

export const meta: IContentMeta<IImageBody> = {
  name: 'Image',
  description: 'Add image to page.',
  icon: 'image',
};
