import { IContentMeta } from '@/componentRegistry';

export interface ICodeBody {
  code: string;
}

export const meta: IContentMeta<ICodeBody> = {
  name: 'Code',
  description: 'Add source code',
  icon: 'code',
  body: {
    code: 'console.log("Hello world!");',
  },
};
